import { useState, useEffect, useMemo } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MotiView } from "moti";
import { useNavigation } from "@react-navigation/native";
import { listClasses } from "../../../services";
import { View } from "react-native";
import { useGetAllAssignmentsByCourseQuery } from "../../../graphql/generated/graphql";
import Skeleton from "react-loading-skeleton";
import ClassCardProgressList from "../../../components/lms/ClassCardProgressList/ClassCardProgressList";

function ClassCardProgressListContainer({ integrationId, activeTab }) {
  const [courses, setCourses] = useState([]);
  const [courseIds, setCourseIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigation();

  const { data: assignmentsData, refetch: assignmentsRefetch } = useGetAllAssignmentsByCourseQuery({
    variables: {
      courseIds,
    },
    skip: !courses.length,
  });

  const filteredCourses = useMemo(() => {
    if (activeTab === "all" || activeTab === "core") {
      return courses;
    } else {
      return [];
      // return courses.filter((course) => course.type.toLowerCase() === activeTab);
    }
  }, [courses, activeTab]);

  function calculateProgress(assignments) {
    if (!assignments || assignments.length === 0) return 0;

    let totalObjectives = 0;
    let completedObjectives = 0;

    assignments.forEach((assignment) => {
      const objectives = assignment.objectives;
      if (objectives && objectives.length > 0) {
        totalObjectives += objectives.length;
        completedObjectives += objectives.filter((objective) => objective.status === "completed").length;
      } else {
        totalObjectives += 4;
      }
    });

    return totalObjectives > 0 ? Math.round((completedObjectives / totalObjectives) * 100) : 0;
  }

  function formatCoursesData(courses, assignmentsData) {
    return courses.map((course) => {
      const assignments = assignmentsData.filter((assignment) => assignment.courseId === course.id);

      const progress = calculateProgress(assignments);

      return {
        ...course,
        progress,
      };
    });
  }

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const token = await AsyncStorage.getItem("token");
      const data = await listClasses(token, integrationId);
      const ids = data ? data.map((course) => course.id) : [];

      setCourses(data);
      setCourseIds(ids);
      setIsLoading(false);
    };

    fetchData();
  }, [integrationId]);

  useEffect(() => {
    if (courseIds.length) {
      assignmentsRefetch();
    }
  }, [courseIds, assignmentsRefetch]);

  const handlePress = (id) => {
    const course = courses.filter((course) => course.id === id)[0];
    navigation.replace("Custom Course Student", {
      course_id: course.id,
      integration_id: integrationId,
    });
  };

  const handleOnHoverIn = (id) => {
    setCourses((prevCourses) =>
      prevCourses.map((course) => {
        if (course.id === id) {
          return {
            ...course,
            isHovered: true,
          };
        }

        return course;
      })
    );
  };

  const handleOnHoverOut = (id) => {
    setCourses((prevCourses) =>
      prevCourses.map((course) => {
        if (course.id === id) {
          return {
            ...course,
            isHovered: false,
          };
        }

        return course;
      })
    );
  };

  return (
    <MotiView
      from={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      delay={500}
      transition={{ type: "timing", duration: 300 }}
      style={{ flex: 1 }}
      aria-live="polite">
      {isLoading ? (
        <View style={{ flexDirection: "row", flexWrap: "wrap", gap: 20, marginBottom: 20, justifyContent: "center" }}>
          {[...Array(3)].map((_, index) => (
            <Skeleton width={400} height={210} margin={10} borderRadius={30} key={index} />
          ))}
        </View>
      ) : (
        <>
          {assignmentsData && filteredCourses && (
            <ClassCardProgressList
              courses={formatCoursesData(filteredCourses, assignmentsData.getAllAssignmentsByCourse)}
              onPress={handlePress}
              onHoverIn={handleOnHoverIn}
              onHoverOut={handleOnHoverOut}
              isLoading={isLoading}
              isNull={filteredCourses.length === 0}
            />
          )}
        </>
      )}
    </MotiView>
  );
}

export default ClassCardProgressListContainer;
