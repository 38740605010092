import React from "react";
import { View, StyleSheet, Pressable } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import { GraduationCap } from "../../svgs/common";
import { useNavigation } from "@react-navigation/native";
import { useAppConfig } from "../../../AppConfigProvider";

const AssignmentCard = ({ dueDay, subject, chapter, color, integrationId, courseId, resourceId }) => {
  const appConfig = useAppConfig();
  const isMoodleOffering = appConfig.isMoodleOffering;
  const navigation = useNavigation();
  const handleViewAssignment = () => {
    navigation.navigate("Resource", {
      resource_type: "assignment",
      resource_id: resourceId,
      course_id: courseId, // Assuming you have classId in your assignment data
      integration_id: integrationId,
    });
  };
  return (
    <View style={[styles.card, { backgroundColor: color }]}>
      <View style={styles.textContainer}>
        <CustomText useTranslationText={false} style={styles.dueText} size="m" text={"Due"} />
        <CustomText useTranslationText={false} style={styles.dueText} size="m" text={dueDay} />
      </View>
      <View style={styles.badge}>
        <CustomText
          useTranslationText={false}
          text={subject}
          numberOfLines={1}
          ellipsizeMode="tail"
          size="s"
          style={[styles.badgeText, { color }]}
        />
      </View>

      <CustomText useTranslationText={false} text={chapter} size="m" style={styles.chapterText} />

      <View style={styles.buttonContainer}>
        <Pressable
          style={styles.button}
          onPress={handleViewAssignment}
          role="button"
          aria-label="View Assignment"
          accessibilityHint="Navigates to the assignment details">
          <CustomText
            useTranslationText={false}
            text={isMoodleOffering ? "View Activity →" : "View Assignment →"}
            size="s"
            style={styles.buttonText}
          />
        </Pressable>
        <GraduationCap style={styles.icon} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    padding: 20,
    borderRadius: 24.39,
    width: 245,
    height: 260,
    marginRight: 16,
    // marginBottom: 16,
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  dueText: {
    color: "white",
    fontSize: 16,
  },

  textContainer: {
    flexDirection: "column",
    marginBottom: 8,
  },

  badge: {
    backgroundColor: "white",
    paddingHorizontal: 8,
    borderRadius: 12,
    alignSelf: "flex-start",
    maxWidth: "100%",
  },
  badgeText: {
    fontSize: 12,
  },
  chapterText: {
    color: "white",
    fontSize: 16,
    maginTop: 0,
    marginBottom: 16,
  },
  button: {
    alignSelf: "flex-start",
  },
  buttonText: {
    color: "white",
    fontSize: 14,
    textDecorationLine: "underline",
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  icon: {
    marginLeft: "auto",
  },
});

export default AssignmentCard;
