import React, { createContext, useContext, useReducer, ReactNode, useState } from "react";
import { RootStackParamList } from "../navigation/AppNavigator.web";

export enum Context {
  TRACK = "track",
  ASSIGNMENT = "assignment",
  JOURNEY = "journey",
  ONBOARDING = "onboarding",
  GENERAL = "general",
}

const mapRouteToContext = (route: keyof RootStackParamList) => {
  switch (route) {
    case "Lesson Activities":
    case "Lesson Assessment":
    case "Lesson Chat":
      return Context.TRACK;
    case "Resource":
      return Context.ASSIGNMENT;
    case "Custom Resource Student":
      return Context.ASSIGNMENT;
    case "Journey":
      return Context.JOURNEY;
    case "Onboarding":
      return Context.ONBOARDING;
    default:
      return Context.GENERAL;
  }
};

export interface SchoolState {
  contentType: string;
  contentId: string;
  classId: string;
  integrationId: string;
}

interface JourneyState {
  journeyId: string;
}

// part of AppStateContext, subject to refactoring later
export interface LearningState {
  state: unknown;
  topic: unknown;
  topicAncestors: unknown;
  label: unknown;
  isUnitAssessment: unknown;
  subchapterName: unknown;
  subchapterDescription: unknown;
  objectives: unknown;
}

export enum ConversationType {
  GENERAL_CONVERSATION = "GENERAL_CONVERSATION",
  GET_SUGGESTIONS = "GET_SUGGESTIONS",
  SCHOOL = "SCHOOL",
  ONBOARD = "ONBOARD",
  LEARNING = "LEARNING",
  JOURNEY = "JOURNEY",
}

interface LLMMeta {
  token: string;
  timeZone: string;
  abort: boolean;
  isExpanded: boolean;
  isSuggestionsLoading: boolean;
  isLoading: boolean;
}

interface LLMMessage {
  meta: LLMMeta;
  state: {
    context: Context;
    params: {
      [key: string]: string;
    };
    general: ConversationType;
    learning: LearningState;
    school: SchoolState;
    journey: JourneyState;
  };
  content: {
    message: string;
    author: "HUMAN" | "LLM";
    type: null;
  };
}

// Define the state type
interface ChatBarState {
  llmMessage: LLMMessage;
  isRecording: boolean;
  isAudioOn: boolean;
  activeContext: Context;
  activeObjectId: string | null;
  activeChat: {
    id: string;
    context: Context;
  } | null;
}

// Define the initial state
const initialState: ChatBarState = {
  llmMessage: {
    meta: {
      token: "",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      abort: true,
      isExpanded: false,
      isLoading: false,
      isSuggestionsLoading: false,
    },
    state: {
      context: Context.GENERAL,
      params: {},
      general: ConversationType.GENERAL_CONVERSATION,
      learning: {} as LearningState,
      school: {} as SchoolState,
      journey: {} as JourneyState,
    },
    content: {
      message: "",
      author: "HUMAN",
      type: null,
    },
  },
  isRecording: false,
  isAudioOn: false,
  activeContext: Context.GENERAL,
  activeObjectId: null,
  activeChat: null,
};

// Define the action types
type ChatBarAction =
  | { type: "TOGGLE_RECORDING" }
  | { type: "SET_AUDIO"; payload: boolean }
  | { type: "SET_CONVERSATION_TYPE"; payload: ConversationType }
  | { type: "SET_SCHOOL_MESSAGE"; payload: SchoolState }
  | { type: "SET_JOURNEY_MESSAGE"; payload: JourneyState }
  | { type: "SET_LEARNING_MESSAGE"; payload: LearningState }
  | { type: "SET_CONTEXT"; payload: { context: Context; params: { [key: string]: string } } }
  | { type: "SET_ACTIVE_CONTEXT_AND_OBJECT_ID"; payload: { context: Context; objectId: string } }
  | { type: "SET_ACTIVE_CHAT"; payload: { id: string; context: Context } }
  | { type: "SET_META_IS_LOADING"; payload: boolean };

// Create the reducer function
const chatBarReducer = (state: ChatBarState, action: ChatBarAction): ChatBarState => {
  switch (action.type) {
    case "TOGGLE_RECORDING":
      return { ...state, isRecording: !state.isRecording };
    case "SET_AUDIO":
      return { ...state, isAudioOn: action.payload };
    case "SET_CONVERSATION_TYPE":
      return {
        ...state,
        llmMessage: { ...state.llmMessage, state: { ...state.llmMessage.state, general: action.payload } },
      };
    case "SET_SCHOOL_MESSAGE":
      return {
        ...state,
        llmMessage: { ...state.llmMessage, state: { ...state.llmMessage.state, school: action.payload } },
      };
    case "SET_JOURNEY_MESSAGE":
      return {
        ...state,
        llmMessage: { ...state.llmMessage, state: { ...state.llmMessage.state, journey: action.payload } },
      };
    case "SET_LEARNING_MESSAGE":
      return {
        ...state,
        llmMessage: { ...state.llmMessage, state: { ...state.llmMessage.state, learning: action.payload } },
      };
    case "SET_CONTEXT":
      return {
        ...state,
        llmMessage: {
          ...state.llmMessage,
          state: { ...state.llmMessage.state, context: action.payload.context, params: action.payload.params },
        },
      };
    case "SET_ACTIVE_CONTEXT_AND_OBJECT_ID":
      return { ...state, activeContext: action.payload.context, activeObjectId: action.payload.objectId };
    case "SET_ACTIVE_CHAT":
      return { ...state, activeChat: action.payload };
    case "SET_META_IS_LOADING":
      return {
        ...state,
        llmMessage: { ...state.llmMessage, meta: { ...state.llmMessage.meta, isLoading: action.payload } },
      };
    default:
      return state;
  }
};

type ChatBarContextType = {
  state: ChatBarState;
  toggleRecording: () => void;
  setAudio: (isAudioOn: boolean) => void;
  setConversationType: (conversationType: ConversationType) => void;
  setSchoolMessage: (schoolState: SchoolState) => void;
  setJourneyMessage: (journeyState: JourneyState) => void;
  setLearningMessage: (learningState: LearningState) => void;
  setContext: (context: { context: Context; params: { [key: string]: string } }) => void;
  setShowChat: (showChat: boolean) => void;
  setActiveContextAndObjectId: ({ context, objectId }: { context: Context; objectId: string }) => void;
  setActiveChat: ({ id, context }: { id: string; context: Context }) => void;
  showChat: boolean;
  mapRouteToContext: (route: keyof RootStackParamList) => Context;
  setMetaIsLoading: (isLoading: boolean) => void;
};

// Create the context
const ChatBarContext = createContext<ChatBarContextType>({
  state: initialState,
  toggleRecording: () => {},
  setAudio: () => {},
  setConversationType: () => {},
  setSchoolMessage: () => {},
  setJourneyMessage: () => {},
  setLearningMessage: () => {},
  setContext: () => {},
  setShowChat: () => {},
  setActiveContextAndObjectId: () => {},
  setActiveChat: () => {},
  showChat: false,
  mapRouteToContext: () => Context.GENERAL,
  setMetaIsLoading: () => {},
});

// Create the provider component
export const ChatBarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(chatBarReducer, initialState);

  const [showChat, setShowChat] = useState(false);

  const toggleRecording = () => dispatch({ type: "TOGGLE_RECORDING" });
  const setAudio = (isAudioOn: boolean) => dispatch({ type: "SET_AUDIO", payload: isAudioOn });
  const setConversationType = (conversationType: ConversationType) =>
    dispatch({ type: "SET_CONVERSATION_TYPE", payload: conversationType });
  const setSchoolMessage = (schoolState: SchoolState) => dispatch({ type: "SET_SCHOOL_MESSAGE", payload: schoolState });
  const setJourneyMessage = (journeyState: JourneyState) =>
    dispatch({ type: "SET_JOURNEY_MESSAGE", payload: journeyState });
  const setLearningMessage = (learningState: LearningState) =>
    dispatch({ type: "SET_LEARNING_MESSAGE", payload: learningState });
  const setContext = (context: { context: Context; params: { [key: string]: string } }) => {
    return dispatch({ type: "SET_CONTEXT", payload: context });
  };
  const setActiveContextAndObjectId = ({ context, objectId }: { context: Context; objectId: string }) =>
    dispatch({ type: "SET_ACTIVE_CONTEXT_AND_OBJECT_ID", payload: { context, objectId } });
  const setActiveChat = ({ id, context }: { id: string; context: Context }) =>
    dispatch({ type: "SET_ACTIVE_CHAT", payload: { id, context } });
  const setMetaIsLoading = (isLoading: boolean) => {
    dispatch({ type: "SET_META_IS_LOADING", payload: isLoading });
  };

  return (
    <ChatBarContext.Provider
      value={{
        state,
        setContext,
        toggleRecording,
        setAudio,
        setConversationType,
        setSchoolMessage,
        setJourneyMessage,
        setLearningMessage,
        setShowChat,
        showChat,
        setActiveContextAndObjectId,
        setActiveChat,
        mapRouteToContext,
        setMetaIsLoading,
      }}>
      {children}
    </ChatBarContext.Provider>
  );
};

// Create a custom hook to use the context
export const useChatBar = () => {
  const context = useContext(ChatBarContext);
  if (context === undefined) {
    throw new Error("useChatBar must be used within a ChatBarProvider");
  }
  return context;
};
