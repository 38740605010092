import { useAppConfig } from "../AppConfigProvider";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import withAuth from "../hocs/withAuth";
import DashboardView from "../views/DashboardView";
import ProductivityView from "../views/ProductivityView";
import PortfolioView from "../views/PortfolioView";
import JournalView from "../views/JournalView";
import SchoolView from "../views/SchoolView";
import SchoolsView from "../views/SchoolsView";
import CategoriesView from "../views/CategoriesView";
import TracksView from "../views/TracksView";
import LearnView from "../views/LearnView";
import LessonLearningView from "../views/LessonLearningView";
import LessonChatView from "../views/LessonChatView";
import LessonVideoView from "../views/LessonVideoView";
import HealthView from "../views/HealthView";
import SettingsView from "../views/SettingsView";
import InviteView from "../views/InviteView";
import LoginView from "../views/LoginView";
import LoginLTIView from "../views/LoginLTIView";
import SignupView from "../views/SignupView";
import ResetPasswordView from "../views/ResetPasswordView";
import VerifyEmailView from "../views/VerifyEmailView";
import CourseView from "../views/CourseView";
import OnboardingView from "../views/OnboardingView";
import CareerView from "../views/CareerView";
import CareerDetailView from "../views/CareerDetailView";
import ResourceView from "../views/ResourceView";
import JourneyView from "../views/JourneyView";
import ThankYouView from "../views/ThankYouView";
import TrackOverviewView from "../views/TrackOverviewView";
import LessonAssessmentView from "../views/LessonAssessmentView";
import LessonSubchapterView from "../views/LessonSubchapterView";
import EdlinkSuccessView from "../views/EdlinkSuccessView";
import CustomCourseworkView from "../views/CustomCourseworkView";
import CustomCourseworkCourseFormView from "../views/CustomCourseworkCourseFormView";
import CustomCourseworkModulesView from "../views/CustomCourseworkModulesView";
import AdminView from "../views/AdminView";
import TenantDashboardView from "../views/TenantDashboardView";
import TenantSettingsDashboardView from "../views/TenantSettingsDashboardView";
// import ContentGenerationView from "../views/ContentGenerationView";
// import ModulesView from "../views/ModulesView";
import noAuth from "../hocs/noAuth";
// import ContentFormView from "../views/ContentFormView";
import { useRef } from "react";
import { routingInstrumentation } from "../utils/sentry";
import CustomSchoolStudentView from "../views/CustomSchoolStudentView";
import CustomCourseStudentView from "../views/CustomCourseStudentView";
import CustomResourceStudentView from "../views/CustomResourceStudentView";
import ProjectView from "../views/ProjectView";
import AcademicPlanView from "../views/AcademicPlanView";

// Some views have been disabled for production

export type RootStackParamList = {
  "My Day": undefined;
  Productivity: { tab?: string };
  Portfolio: undefined;
  Tools: undefined;
  Journal: undefined;
  Coursework: undefined;
  "Academic Plan": undefined;
  Activities: undefined;
  School: { integration_id: string };
  BoxSchool: { integration_id: string };
  Course: undefined;
  "Custom Course Student": undefined;
  Career: undefined;
  Content: undefined;
  ContentForm: undefined;
  Modules: undefined;
  Journey: { id: string };
  Project: { id: string };
  CareerDetails: undefined;
  Resource: {
    resource_type: string;
    resource_id: string;
    course_id: string;
    integration_id: string;
  };
  "Custom Resource Student": {
    resource_type: string;
    resource_id: string;
    course_id: string;
    integration_id: string;
  };
  Learn: undefined;
  Categories: undefined;
  Tracks: {
    field: string;
    subfield: string;
  };
  "Track Overview": undefined;
  Lesson: {
    field: string;
    subfield: string;
    topic: string;
    chapter: string;
    subchapter: string;
  };
  "Lesson Activities": {
    field: string;
    subfield: string;
    topic: string;
    chapter: string;
    subchapter: string;
    loTitle: string;
    loId: string;
  };
  "Lesson Assessment": {
    field: string;
    subfield: string;
    topic: string;
    chapter: string;
    subchapter: string;
    loTitle: string;
    loId: string;
  };
  "Lesson Chat": { loId: string };
  "Lesson Video": undefined;
  Health: undefined;
  Onboarding: undefined;
  Settings: undefined;
  Invite: undefined;
  Login: { isLMS: boolean };
  LoginLTI: { token?: string };
  Signup: { isLMS: boolean };
  "Reset Password": { token?: string };
  "Verify Email": undefined;
  ThankYou: undefined;
  EdlinkSuccess: undefined;
  "Custom Coursework": undefined;
  "Custom Coursework Course Form": {
    courseId: string;
  };
  "Custom School Student": { integration_id: string };
  "Custom Coursework Modules": {
    courseId: string;
    assignmentIndex?: number;
    pageIndex?: number;
    moduleIndex?: number;
  };
  Admin: undefined;
  "Tenant Dashboard": undefined;
  "Tenant Settings": { id: string };
};

const Stack = createStackNavigator<RootStackParamList>();

function AppNavigator() {
  const appConfig = useAppConfig();
  const navigation = useRef();

  const currentDomain = appConfig.currentDomain;
  const isLMS = appConfig.isLMS;
  const isMoodleOffering = appConfig.isMoodleOffering;

  const linking = {
    prefixes: [`https://${currentDomain}`, "primer://"],
    config: {
      screens: {
        "My Day": "my-day",
        Productivity: "productivity",
        Porfolio: "portfolio",
        "Academic Plan": "academic-plan",
        Journal: "journal",
        Coursework: "coursework",
        Activities: "activities",
        School: "school",
        "Custom School Student": "student/school",
        Course: "school/course",
        "Custom Course Student": "student/school/course",
        Resource: "school/course/resource",
        "Custom Resource Student": "student/school/course/resource",
        Learn: "learn",
        Tracks: "tracks",
        "Track Overview": "track/overview",
        Categories: "categories",
        Lesson: "learn/lesson",
        "Lesson Activities": "learn/lesson/activities",
        "Lesson Assessment": "learn/lesson/assessment",
        "Lesson Chat": "learn/lesson/chat",
        "Lesson Video": "learn/lesson/video",
        Health: "health",
        Onboarding: "onboarding",
        Settings: "settings",
        Invite: "invite",
        Login: "login",
        LoginLTI: "login/lti",
        Signup: "signup",
        "Reset Password": "reset-password",
        "Verify Email": "verify-email",
        Career: "career",
        Content: "content",
        Modules: "content/modules",
        ContentForm: "content/form",
        Journey: "journey",
        Project: "project",
        CareerDetails: "career/details",
        ThankYou: "thank-you",
        EdlinkSuccess: "edlink-success",
        "Custom Coursework": "custom-coursework",
        "Custom Coursework Course Form": "custom-coursework/course-form",
        "Custom Coursework Modules": "custom-coursework/modules",
        Admin: "dashboard",
        "Tenant Dashboard": "dashboard/tenent",
        "Tenant Settings": "dashboard/tenent/settings",
      },
    },
  };

  return (
    <NavigationContainer
      linking={linking}
      ref={navigation}
      onReady={() => {
        routingInstrumentation.registerNavigationContainer(navigation);
      }}>
      <Stack.Navigator
        initialRouteName={isMoodleOffering ? "Custom School Student" : "My Day"}
        screenOptions={{ headerShown: false }}>
        <Stack.Screen name="My Day" component={withAuth(DashboardView)} />
        <Stack.Screen name="Productivity" component={withAuth(ProductivityView)} />
        <Stack.Screen name="Portfolio" component={withAuth(PortfolioView)} />
        <Stack.Screen name="Journal" component={withAuth(JournalView)} />
        <Stack.Screen name="Coursework" component={withAuth(SchoolsView)} />
        <Stack.Screen name="Academic Plan" component={withAuth(AcademicPlanView)} />
        {/* <Stack.Screen name="Tools" component={withAuth(ToolsView)} /> */}
        <Stack.Screen name="Activities" component={withAuth(SchoolsView)} />
        <Stack.Screen name="School" component={withAuth(SchoolView)} />
        <Stack.Screen
          name="Custom School Student"
          component={withAuth(CustomSchoolStudentView)}
          initialParams={{ integration_id: "1" }}
        />
        <Stack.Screen name="Course" component={withAuth(CourseView)} />
        <Stack.Screen name="Custom Course Student" component={withAuth(CustomCourseStudentView)} />
        <Stack.Screen name="Career" component={withAuth(CareerView)} />
        {/* <Stack.Screen name="Content" component={withAuth(ContentGenerationView)} /> */}
        {/* <Stack.Screen name="ContentForm" component={withAuth(ContentFormView)} /> */}
        <Stack.Screen name="Journey" component={withAuth(JourneyView)} />
        <Stack.Screen name="Project" component={withAuth(ProjectView)} />
        <Stack.Screen name="CareerDetails" component={withAuth(CareerDetailView)} />
        <Stack.Screen name="Resource" component={withAuth(ResourceView)} />
        <Stack.Screen name="Custom Resource Student" component={withAuth(CustomResourceStudentView)} />
        <Stack.Screen name="Learn" component={withAuth(LearnView)} />
        <Stack.Screen name="Categories" component={withAuth(CategoriesView)} />
        <Stack.Screen name="Tracks" component={withAuth(TracksView)} />
        <Stack.Screen name="Track Overview" component={withAuth(TrackOverviewView)} />
        <Stack.Screen name="Lesson" component={withAuth(LessonSubchapterView)} />
        {/* <Stack.Screen name="Modules" component={withAuth(ModulesView)} /> */}
        <Stack.Screen name="Lesson Activities" component={withAuth(LessonLearningView)} />
        <Stack.Screen name="Lesson Assessment" component={withAuth(LessonAssessmentView)} />
        <Stack.Screen name="Lesson Chat" component={withAuth(LessonChatView)} />
        <Stack.Screen name="Lesson Video" component={withAuth(LessonVideoView)} />
        <Stack.Screen name="Health" component={withAuth(HealthView)} />
        <Stack.Screen name="Onboarding" component={withAuth(OnboardingView)} />
        <Stack.Screen name="Settings" component={withAuth(SettingsView)} />
        <Stack.Screen name="Invite" component={withAuth(InviteView)} />
        <Stack.Screen name="Login" component={noAuth(LoginView)} initialParams={{ isLMS }} />
        <Stack.Screen name="LoginLTI" component={noAuth(LoginLTIView)} />
        <Stack.Screen name="Signup" component={noAuth(SignupView)} initialParams={{ isLMS }} />
        <Stack.Screen name="Reset Password" component={noAuth(ResetPasswordView)} />
        <Stack.Screen name="Verify Email" component={noAuth(VerifyEmailView)} />
        <Stack.Screen name="ThankYou" component={withAuth(ThankYouView)} />
        <Stack.Screen name="EdlinkSuccess" component={withAuth(EdlinkSuccessView)} />
        <Stack.Screen name="Custom Coursework" component={withAuth(CustomCourseworkView)} />
        <Stack.Screen name="Custom Coursework Course Form" component={withAuth(CustomCourseworkCourseFormView)} />
        <Stack.Screen name="Custom Coursework Modules" component={withAuth(CustomCourseworkModulesView)} />
        <Stack.Screen name="Admin" component={withAuth(AdminView)} />
        <Stack.Screen name="Tenant Dashboard" component={withAuth(TenantDashboardView)} />
        <Stack.Screen name="Tenant Settings" component={withAuth(TenantSettingsDashboardView)} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default AppNavigator;
