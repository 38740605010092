import { useState, useRef, useCallback } from "react";
import { useAppState } from "../../../contexts/AppStateContext";
import { useAppConfig } from "../../../AppConfigProvider";
import { useFocusEffect, useRoute } from "@react-navigation/native";
import { useMeQuery } from "../../../graphql/generated/graphql";
import ChatBar from "../../../components/navigation/ChatBar/ChatBar";
import UpgradeModalContainer from "../../general/UpgradeModalContainer/UpgradeModalContainer";
import useAudioRecorder from "../../../hooks/useAudioRecorder";
import { useChatBar, Context } from "../../../contexts/ChatBarContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import posthog from "posthog-js";
import useWebSocket from "../../../hooks/useWebSocket";
import { useChatBarInput } from "../../../contexts/CharBarInputContext";
import Sentry from "../../../utils/sentry";

const viewsWithoutChat = ["Lesson", "Lesson Video", "Lesson Assessment", "Lesson Activities"];

export const viewsWithoutChatWindow = [
  "Lesson",
  "Lesson Video",
  "Lesson Assessment",
  "Lesson Activities",
  "Lesson Chat",
  "Onboarding",
];

function ChatBarContainer({ onSubmit = () => {} }) {
  const { companionName } = useAppConfig();
  const appConfig = useAppConfig();
  const isMoodleOffering = appConfig.isMoodleOffering;
  const { mapRouteToContext } = useChatBar();

  const { state, handleAudioOffPress, handleAudioOnPress, actions, dispatch } = useAppState();
  const { setShowChat, showChat, state: chatBarState, setMetaIsLoading } = useChatBar();
  const { setInputValue, inputValue } = useChatBarInput();
  const { sendMessage } = useWebSocket();

  const route = useRoute();

  const { data } = useMeQuery();

  const chatInputRef = useRef(null);

  const { recording, toggleRecording } = useAudioRecorder(onSubmit);

  const [isUpgradeModalVisible, setIsUpgradeModalVisible] = useState(false);

  const showChatWindow = !viewsWithoutChatWindow.includes(route.name);

  useFocusEffect(
    useCallback(() => {
      // Function to execute when the screen is focused
      const handleDocumentKeyPress = () => {
        chatInputRef.current?.focus();
      };

      if (state.meta.keypressIsListening) {
        // Add the event listener only if input is not focused and the modal is not open
        document.addEventListener("keypress", handleDocumentKeyPress);
      } else {
        // Remove the event listener if input is focused or the modal is open
        document.removeEventListener("keypress", handleDocumentKeyPress);
      }

      // Cleanup the event listener when the component is unmounted
      return () => {
        document.removeEventListener("keypress", handleDocumentKeyPress);
      };
    }, [state.meta.keypressIsListening])
  );

  const setAudioOn = () => {
    if (!data?.me?.isPro && state.meta.isOnboarded) {
      setIsUpgradeModalVisible(true);
    } else {
      handleAudioOnPress();
    }
  };

  const handleRecordingStopPress = () => {
    toggleRecording();
  };

  const handleRecordingStartPress = () => {
    if (!data?.me?.isPro && state.meta.isOnboarded) {
      setIsUpgradeModalVisible(true);
    } else {
      toggleRecording();
    }
  };

  const handleCloseUpgradeModals = () => {
    setIsUpgradeModalVisible(false);
  };

  const handleToggleChat = () => {
    setShowChat(!showChat);
  };

  const handleQuickOptionPress = (value) => {
    handleSubmitEditing(showChatWindow, value);
  };

  const handleSubmit = () => {
    handleSubmitEditing(showChatWindow, undefined);
    onSubmit();
  };

  const handleChangeText = (text) => {
    setInputValue(text);
  };

  const handleGetSuggestionFromAxio = async () => {
    const token = await AsyncStorage.getItem("token");
    const schoolParams =
      chatBarState.activeContext === Context.ASSIGNMENT
        ? {
            contentType: chatBarState.llmMessage?.state?.school?.contentType,
            contentId: chatBarState.llmMessage?.state?.school?.contentId,
            classId: chatBarState.llmMessage?.state?.school?.classId,
            integrationId: chatBarState.llmMessage?.state?.school?.integrationId,
          }
        : null;

    sendMessage({
      meta: {
        token: token,
        abort: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isLoading: true,
      },
      state: {
        general: "GET_SUGGESTIONS",
        context: mapRouteToContext(route.name),
        params: route.params,
        school: schoolParams,
      },
      content: {
        message: "Get Suggestions",
        author: "HUMAN",
        type: null,
      },
    });
    setMetaIsLoading(true);
    setShowChat(true);
  };
  const handleSubmitEditing = async (showChatWindow, quickItemValue) => {
    try {
      posthog?.capture("message sent to axio");

      if (state.meta.isAdventure) {
        dispatch({
          type: actions.SET_META,
          payload: { isAdventure: false },
        });
      }

      await sendMessageToAxio(quickItemValue);

      if (showChatWindow) {
        setShowChat(true);
      }

      setInputValue("");
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const sendMessageToAxio = async (quickItemValue) => {
    const token = await AsyncStorage.getItem("token");

    setMetaIsLoading(true);
    sendMessage({
      ...chatBarState.llmMessage,
      meta: { ...chatBarState.llmMessage.meta, token, isLoading: true },
      content: { ...chatBarState.llmMessage.content, message: quickItemValue || inputValue },
      state: {
        ...chatBarState.llmMessage.state,
      },
    });
  };

  if (viewsWithoutChat.includes(route.name)) {
    return null;
  }

  return (
    <>
      <ChatBar
        value={inputValue}
        isRecording={recording}
        isAudioOn={state.meta.isAudioOn}
        placeholder={recording ? `${companionName} is listening...` : `Chat with ${companionName}...`}
        inputRef={chatInputRef}
        onChangeText={handleChangeText}
        onSubmitEditing={handleSubmit}
        onAudioOffPress={handleAudioOffPress}
        onAudioOnPress={setAudioOn}
        onRecordingStopPress={handleRecordingStopPress}
        onRecordingStartPress={handleRecordingStartPress}
        onToggleChatPress={handleToggleChat}
        onQuickOptionPress={handleQuickOptionPress}
        onGetSuggestionsPress={handleGetSuggestionFromAxio}
        isCustomOffering={isMoodleOffering}
        showQuickOptions={true}
        showChatWindow={showChatWindow}
        showChat={showChat}
      />

      <UpgradeModalContainer visible={isUpgradeModalVisible} onClose={handleCloseUpgradeModals} />
    </>
  );
}

export default ChatBarContainer;
