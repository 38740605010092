import { useState, useEffect, useMemo } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MotiView } from "moti";
import { getModulesWithResources } from "../../../services";
import { useNavigation } from "@react-navigation/native";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Sentry from "../../../utils/sentry";
import { useAssignmentsQuery } from "../../../graphql/generated/graphql";
import Skeleton from "react-loading-skeleton";
import AssignmentCardProgressList from "../../../components/lms/AssignmentCardProgressList/AssignmentCardProgressList";

const AssignmentCardProgressListContainer = ({ integrationId, courseId, onProgressUpdate }) => {
  const [modulesWithResources, setModulesWithResources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigation();

  const { data: assignmentsData } = useAssignmentsQuery();

  // Memoize the assignment map for efficient lookups
  const assignmentMap = useMemo(() => {
    const map = new Map();
    if (assignmentsData?.getAssignments) {
      assignmentsData.getAssignments.forEach((assignment) => {
        map.set(assignment.id, assignment);
      });
    }
    return map;
  }, [assignmentsData]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const token = await AsyncStorage.getItem("token");
      try {
        const mod = await getModulesWithResources(token, integrationId, courseId);

        let totalObjectivesCount = 0;
        let completedObjectivesCount = 0;

        const formattedMod = mod.map((module) => {
          const resources = module.resources.map((resource) => {
            const assignment = assignmentMap.get(resource.target_id);

            // Extract total objectives from resource
            let totalObjectives = 0;
            let resourceObjectives = [];
            if (resource.details.objectives) {
              const objectivesString = resource.details.objectives.replace("<p>", "").replace("</p>", "");
              try {
                resourceObjectives = JSON.parse(objectivesString);
                totalObjectives = resourceObjectives.length;
              } catch (e) {
                console.error("Failed to parse resource objectives", e);
              }
            }

            totalObjectivesCount += totalObjectives;

            // Calculate completed objectives from assignment
            let completedObjectives = 0;
            if (assignment?.objectives && assignment.objectives.length > 0) {
              completedObjectives = assignment.objectives.filter(
                (objective) => objective.status === "completed"
              ).length;
            }
            completedObjectivesCount += completedObjectives;

            const isComplete = completedObjectives > 0 && completedObjectives === totalObjectives;

            // Calculate resource duration
            const resourceDurationMinutes = (totalObjectives - completedObjectives) * 10;

            return {
              ...resource,
              details: {
                ...resource.details,
                isComplete,
                totalObjectives,
                completedObjectives,
                resourceDurationMinutes,
              },
            };
          });

          return {
            ...module,
            resources,
          };
        });

        // Calculate total progress and total duration
        const totalProgress = totalObjectivesCount > 0 ? (completedObjectivesCount / totalObjectivesCount) * 100 : 0;
        const totalDurationMinutes = (totalObjectivesCount - completedObjectivesCount) * 10;
        onProgressUpdate(totalProgress, totalDurationMinutes);

        setModulesWithResources(formattedMod);
      } catch (error) {
        console.error("Error formatting modules", error);
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [integrationId, courseId, assignmentsData]);

  const handleResourcePress = (id) => {
    const resource = modulesWithResources.flatMap((module) => module.resources).find((res) => res.id === id);

    if (resource && resource.details) {
      const params = {
        resource_type: resource.type,
        resource_id: resource.details.id,
        course_id: courseId,
        integration_id: integrationId,
      };

      navigation.navigate("Custom Resource Student", params);
    } else {
      Sentry.captureException(`Resource with id ${id} not found or missing details.`);
    }
  };

  const handleOnHoverIn = (id) => {
    setModulesWithResources((prev) =>
      prev.map((module) => ({
        ...module,
        resources: module.resources.map((resource) =>
          resource.id === id ? { ...resource, isHovered: true } : resource
        ),
      }))
    );
  };

  const handleOnHoverOut = (id) => {
    setModulesWithResources((prev) =>
      prev.map((module) => ({
        ...module,
        resources: module.resources.map((resource) =>
          resource.id === id ? { ...resource, isHovered: false } : resource
        ),
      }))
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <ScrollView style={{ flex: 1 }}>
          {[1, 2, 3].map((_, idx) => (
            <View key={idx}>
              <CustomSpacing type="vertical" size="l" />
              <Skeleton height={18} width={60} />
              <CustomSpacing type="vertical" size="s" />
              <Skeleton height={32} width={200} />
              <CustomSpacing type="vertical" size="l" />
              {[1, 2, 3].map((__, idx2) => (
                <Skeleton height={80} style={{ marginBottom: 10 }} key={idx2} />
              ))}
            </View>
          ))}
        </ScrollView>
      );
    }

    return (
      <ScrollView style={{ flex: 1 }}>
        {modulesWithResources.map((module) => (
          <View key={module.id}>
            <CustomSpacing type="vertical" size="l" />
            <CustomText
              text={"MODULE"}
              size="m"
              style={{ fontFamily: "Inter", fontSize: 12, lineHeight: 18, letterSpacing: "0.06", color: "#667085" }}
              useTranslationText={false}
            />
            <CustomText
              text={module.title}
              size="l"
              style={{ fontFamily: "PangaiaBold", fontSize: 24, lineHeight: 32 }}
              useTranslationText={false}
            />
            <CustomSpacing type="vertical" size="l" />
            <AssignmentCardProgressList
              items={module.resources}
              onPress={handleResourcePress}
              onHoverIn={handleOnHoverIn}
              onHoverOut={handleOnHoverOut}
            />
          </View>
        ))}
      </ScrollView>
    );
  };

  return (
    <MotiView
      from={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      delay={1500}
      transition={{ type: "timing", duration: 600 }}
      style={{ flex: 1 }}
      aria-live="polite">
      {renderContent()}
    </MotiView>
  );
};

export default AssignmentCardProgressListContainer;
