/* eslint-disable react-native/no-unused-styles */
import React, { useState, useRef, useEffect, useMemo } from "react";
import { View, StyleSheet, Pressable, Animated, Dimensions } from "react-native";
import ChatMenuContainer from "../../chatmenu/ChatMenuContainer/ChatMenuContainer";
import ChatContainer from "../../chat/ChatContainer/ChatContainer";
import ChatTitle from "../../../components/chatWindow/ChatTitle/ChatTitle";
import { useChatBar, ConversationType } from "../../../contexts/ChatBarContext";
import { Ionicons } from "@expo/vector-icons";
import ChatBarContainer from "../../navigation/ChatBarContainer/ChatBarContainer";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { ChatMenu } from "../../../components/svgs/common";
import { useTodayChatQuery, useGetChatByObjectIdQuery, useGetChatsQuery } from "../../../graphql/generated/graphql";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import { Context } from "../../../contexts/ChatBarContext";
import useWebSocket from "../../../hooks/useWebSocket";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";
import UpgradeModalContainer from "../../general/UpgradeModalContainer/UpgradeModalContainer";
import { useAppConfig } from "../../../AppConfigProvider";

const ChatWindowContainer = () => {
  const { state: appState } = useAppState();
  const {
    setContext,
    setShowChat,
    state: chatBarState,
    setActiveContextAndObjectId,
    setConversationType,
    setSchoolMessage,
    setJourneyMessage,
  } = useChatBar();
  const { isMedium } = useResponsiveScreen();
  const { sendMessage } = useWebSocket();
  const { state, dispatch } = useAppState();
  const appConfig = useAppConfig();
  const isMoodleOffering = appConfig.isMoodleOffering;

  const [menuVisible, setMenuVisible] = useState(false);

  const [lastActiveChat, setLastActiveChat] = useState(null);
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);

  const { data: chatsData, refetch: refetchChatsData } = useGetChatsQuery();

  // Query for today's chat with skip option
  const {
    data: todayChatData,
    loading: todayChatLoading,
    error: todayChatError,
    refetch: todayChatRefetch,
  } = useTodayChatQuery({
    variables: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

  // Query for chat by object ID with skip option
  const {
    data: chatByObjectIdData,
    loading: chatByObjectIdLoading,
    error: chatByObjectIdError,
    refetch: chatByObjectIdRefetch,
  } = useGetChatByObjectIdQuery({
    variables: {
      objectId: chatBarState.activeObjectId,
      context: chatBarState.activeContext,
    },
    skip: !chatBarState.activeObjectId,
  });

  const selectedChat = useMemo(() => {
    if (chatBarState.activeContext === Context.GENERAL) {
      return todayChatData?.getTodayChat;
    }
    return chatByObjectIdData?.getChatByObjectId;
  }, [chatBarState.activeContext, todayChatData, chatByObjectIdData]);

  useEffect(() => {
    if (appState.meta.isAtDayLimit || appState.meta.isAtLifetimeLimit) {
      setUpgradeModalVisible(true);
    }
  }, [appState.meta.isAtDayLimit, appState.meta.isAtLifetimeLimit]);

  useEffect(() => {
    refetchChatsData();

    if (selectedChat?.context && selectedChat.context !== "general") {
      setLastActiveChat(selectedChat);
    }
  }, [selectedChat]);

  useEffect(() => {
    if (chatBarState.activeObjectId) {
      chatByObjectIdRefetch({
        objectId: chatBarState.activeObjectId,
        context: chatBarState.activeContext,
      });
    } else {
      todayChatRefetch({
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    }
  }, [appState.meta.chatHistory]);

  // Animation setup
  const slideAnim = useRef(new Animated.Value(0)).current; // Start at the default position

  const handleModalClose = () => {
    setUpgradeModalVisible(false);
  };

  const handleMenuToggle = () => {
    if (menuVisible) {
      // Slide out to the left (hide)
      Animated.timing(slideAnim, {
        toValue: 0, // Back to the initial position
        duration: 300,
        useNativeDriver: true,
      }).start(() => setMenuVisible(false)); // Hide after animation
    } else {
      setMenuVisible(true); // Set visible before starting the animation
      // Slide in from the left
      Animated.timing(slideAnim, {
        toValue: 270, // Slide the container to the right
        duration: 300,
        useNativeDriver: true,
      }).start();
    }
  };

  // const todayChatId = todayChatData?.getTodayChat?.id;

  const handleGeneralChatTitlePress = async () => {
    dispatch({
      type: actions.SET_CONTENT,
      payload: { message: "" },
    });

    todayChatRefetch({
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    setLastActiveChat(chatByObjectIdData?.getChatByObjectId);

    setConversationType(ConversationType.GENERAL_CONVERSATION);

    setActiveContextAndObjectId({
      context: Context.GENERAL,
      objectId: "",
    });

    setContext({
      context: Context.GENERAL,
      params: null,
    });

    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: {
        token: token,
        abort: true,
      },
    });
  };

  const handleChatTitlePress = async () => {
    dispatch({
      type: actions.SET_CONTENT,
      payload: { message: "" },
    });

    chatByObjectIdRefetch({
      objectId: lastActiveChat.objectId,
      context: lastActiveChat.context,
    });

    setConversationType(
      lastActiveChat.context === Context.ASSIGNMENT ? ConversationType.SCHOOL : ConversationType.JOURNEY
    );

    if (lastActiveChat.context === Context.JOURNEY) {
      setJourneyMessage({
        journeyId: lastActiveChat.objectId,
      });
    }

    if (lastActiveChat.context === Context.ASSIGNMENT) {
      const { resource_id, course_id, integration_id, resource_type } = lastActiveChat.params;

      setSchoolMessage({
        contentType: resource_type,
        contentId: resource_id,
        classId: course_id,
        integrationId: integration_id,
      });
    }

    setActiveContextAndObjectId({
      context: lastActiveChat.context,
      objectId: lastActiveChat.objectId,
    });

    setContext({
      context: lastActiveChat.context as Context,
      params: lastActiveChat.params as { [key: string]: string },
    });

    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: {
        token: token,
        abort: true,
      },
    });
  };

  const handleChatPress = async (chat) => {
    chatByObjectIdRefetch({
      objectId: chat.objectId,
      context: chat.context,
    });
  };

  return (
    <>
      {isMedium ? (
        <>
          <View style={styles(isMedium).container}>
            <View style={styles(isMedium).menu}>
              <ChatMenuContainer
                chatsData={chatsData}
                onChatPress={handleChatPress}
                onMobileChatPress={handleMenuToggle}
              />
            </View>

            <View style={styles(isMedium).chatContent}>
              <Pressable
                role="button"
                aria-label="Close chat window"
                style={styles(isMedium).close}
                onPress={() => setShowChat(false)}>
                <Ionicons name="close" size={24} color="black" />
              </Pressable>

              <View style={styles(isMedium).chatTitleRow}>
                {lastActiveChat && (
                  <ChatTitle
                    title={lastActiveChat?.name}
                    active={chatBarState.activeContext !== Context.GENERAL}
                    onChatPress={handleChatTitlePress}
                  />
                )}
                {!isMoodleOffering ? (
                  <ChatTitle
                    title={isMoodleOffering ? "Coaching" : "General Chat"}
                    active={chatBarState.activeContext === Context.GENERAL}
                    onChatPress={handleGeneralChatTitlePress}
                  />
                ) : (
                  <></>
                )}
              </View>

              <ChatContainer chatData={selectedChat} />
              <View style={styles(isMedium).bar}>
                <ChatBarContainer />
              </View>
            </View>
          </View>
        </>
      ) : (
        <>
          <View style={styles().mobile}>
            <Animated.View style={[styles().mobileContainer, { transform: [{ translateX: slideAnim }] }]}>
              <View style={styles().topRow}>
                <Pressable
                  role="button"
                  aria-label="Toggle chat menu"
                  style={styles().menuButton}
                  onPress={handleMenuToggle}>
                  <ChatMenu />
                </Pressable>

                {lastActiveChat && (
                  <ChatTitle
                    title={lastActiveChat?.name}
                    active={chatBarState.activeContext !== Context.GENERAL}
                    onChatPress={handleChatTitlePress}
                  />
                )}
                {!isMoodleOffering ? (
                  <ChatTitle
                    title={isMoodleOffering ? "Coaching" : "General Chat"}
                    active={chatBarState.activeContext === Context.GENERAL}
                    onChatPress={handleGeneralChatTitlePress}
                  />
                ) : (
                  <></>
                )}

                <Pressable
                  role="button"
                  aria-label="Close chat window"
                  style={styles().closeButton}
                  onPress={() => setShowChat(false)}>
                  <Ionicons name="close" size={24} color="black" />
                </Pressable>
              </View>

              <ChatContainer chatData={selectedChat} />
            </Animated.View>

            <View style={styles().bar}>
              <ChatBarContainer />
            </View>

            {/* ChatMenuContainer that will slide in */}
            <Animated.View style={[styles().chatMenuContainer, { transform: [{ translateX: slideAnim }] }]}>
              <ChatMenuContainer
                chatsData={chatsData}
                onChatPress={handleChatPress}
                onMobileChatPress={handleMenuToggle}
              />
            </Animated.View>
          </View>
        </>
      )}
      <UpgradeModalContainer visible={upgradeModalVisible} onClose={handleModalClose} />
    </>
  );
};

const styles = (isMedium?: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: "row",
      backgroundColor: isMedium ? "rgba(255, 255, 255, 0.95)" : "#ffffff", // Solid color on mobile, transparent on web
      ...(Platform.OS === "web" &&
        isMedium && {
          backdropFilter: "blur(10px)", // Blur effect only on web when isMedium is true
        }),
    },
    mobileContainer: {
      flex: 1,
      backgroundColor: isMedium ? "rgba(255, 255, 255, 0.95)" : "#ffffff", // Adjust based on isMedium
    },
    chatMenuContainer: {
      position: "absolute",
      top: 0,
      left: -270,
      bottom: 56,
      zIndex: 100,
    },
    chatContent: {
      flex: 1,
      paddingTop: 20,
      paddingHorizontal: 20,
    },
    close: {
      alignSelf: "flex-end",
    },
    bar: {
      width: "100%",
      maxWidth: 1400,
      alignSelf: "center",
      zIndex: 2001,
      bottom: 0,
      left: 0,
      right: 0,
    },
    topRow: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingHorizontal: 15,
      paddingVertical: 10,
      elevation: 2, // Adds a subtle shadow
    },
    chatTitleRow: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    menuButton: {
      justifyContent: "flex-start",
    },
    closeButton: {
      height: 24,
      width: 24,
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    mobile: {
      flex: 1,
    },
    menu: {
      zIndex: 2000,
      paddingVertical: 20,
      paddingLeft: 20,
    },
  });

export default ChatWindowContainer;
