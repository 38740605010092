import { useEffect, useState, useCallback } from "react";
import { usePostHog } from "posthog-js/react";
import { View, StyleSheet } from "react-native";
import CustomText from "../components/common/general/CustomText/CustomText";
import { useNavigation, useRoute } from "@react-navigation/native";
import { getClass } from "../services";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MotiView } from "moti";
import { useSchoolsQuery } from "../graphql/generated/graphql";
import TimeLeftSmall from "../components/svgs/common/TimeLeftSmall";
import CustomProgressBar from "../components/common/general/CustomProgressBar/CustomProgressBar";
import CoreTitle from "../components/svgs/common/CoreTitle";
import JourneyBreadcrumbs from "../components/journey/JourneyBreadcrumbs/JourneyBreadcrumbs";
import Skeleton from "react-loading-skeleton";
import AssignmentCardProgressListContainer from "../containers/school/AssignmentCardProgressListContainer/AssignmentCardProgressListContainer";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";

const GOOGLE_PROVIDER = "Google";

function CustomCourseStudentView() {
  const posthog = usePostHog();
  const navigation = useNavigation();
  const route = useRoute();
  const { data: schoolsData, loading, error } = useSchoolsQuery();

  const params = route.params;
  const courseId = params.course_id;
  const [courseName, setCourseName] = useState(courseId);

  const integrationId = params.integration_id;

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""}${remainingMinutes > 0 ? ` ${remainingMinutes} min` : ""}`;
    } else {
      return `${minutes} min`;
    }
  };

  const handleProgressUpdate = useCallback(
    (progress, durationMinutes) => {
      setCourseProgress(progress);
      setTimeLeft(formatDuration(durationMinutes));
    },
    [formatDuration] // Add any dependencies used inside the function
  );

  const [courseProgress, setCourseProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState("");

  const schoolProvider = schoolsData?.getSchools.find((school) => school.id === integrationId)?.providerName;

  const isGoogleProvider = schoolProvider === GOOGLE_PROVIDER && !loading && !error;

  // Fetch course data and check if there's sequencing data
  useEffect(() => {
    const fetchData = async () => {
      const token = await AsyncStorage.getItem("token");
      const data = await getClass(token, integrationId, courseId);

      setCourseName(data.name);
    };

    fetchData();
  }, [integrationId, courseId, isGoogleProvider]);

  useEffect(() => {
    posthog?.capture("School Page Visited");
  }, []);

  const handleBackPress = () => {
    navigation.navigate("Custom School Student", {
      integration_id: integrationId,
    });
  };

  return (
    <NoChatBarLayout>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={800}
        transition={{ type: "timing", duration: 600 }}>
        <View style={styles.headerContainer}>
          <JourneyBreadcrumbs onPress={handleBackPress} firstItem="Courses" secondItem={courseName} />

          <View style={styles.headerTitleRow}>
            <CoreTitle />
            <CustomText
              useTranslationText={false}
              text={courseName}
              size="xxl"
              style={{ fontFamily: "PangaiaBold", fontSize: 46, lineHeight: 44, fontWeight: 800, marginLeft: 8 }}
            />
          </View>
        </View>

        {timeLeft !== "" ? (
          <View style={[styles.courseProgress, { flex: 1, flexDirection: "row", alignItems: "center", gap: 16 }]}>
            <View style={{ maxWidth: 200, flex: 1 }}>
              <CustomProgressBar progress={courseProgress} color="#3E68FE" />
            </View>
            <View style={styles.progressInfo}>
              <TimeLeftSmall size={16} color="#667085" />
              <CustomText text={`${timeLeft} left`} size="s" style={styles.timeText} useTranslationText={false} />
            </View>
          </View>
        ) : (
          <View style={[styles.courseProgress, { flex: 1, flexDirection: "row", alignItems: "center", gap: 16 }]}>
            <View style={{ maxWidth: 200, flex: 1 }}>
              <Skeleton height={8} width="100%" />
            </View>
            <View style={styles.progressInfo}>
              <Skeleton circle={true} height={16} width={16} />
              <Skeleton height={16} width={80} />
            </View>
          </View>
        )}
      </MotiView>
      <AssignmentCardProgressListContainer
        integrationId={integrationId}
        courseId={courseId}
        onProgressUpdate={handleProgressUpdate}
      />
    </NoChatBarLayout>
  );
}

const styles = StyleSheet.create({
  headerTitleRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  headerContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  courseProgress: {
    marginVertical: 12,
  },
  progressInfo: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
  timeText: {
    color: "#667085",
  },
});

export default CustomCourseStudentView;
